import { LogoImage } from 'customizations'
import { User, MenuApi } from 'api'
import { withRouter } from "react-router"
import { SmartButtonStub, SmartButtonLink } from 'ui/smart-buttons'
import LoginModal from 'ui/public/LoginModal'
import Modal from 'shared/modal'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBars, faTimes } from '@fortawesome/free-solid-svg-icons'

class Navbar extends Component {
  state = { dayTime: 'Day', showSidebar: false, openLoginModal: false }

  componentDidMount() {
    this.timer = setInterval(this.tick, 60000)
    this.tick()
    this.loadMenus()
    if(this.queryParams('openSignIn') == 'true')
      this.showLoginModal()
    application.state.on("user:update", () => this.forceUpdate())
  }

  componentWillUnmount() {
    clearInterval(this.timer)
  }

  renderLogo() {
    const { current: { logoUrl } } = User
    if(!logoUrl || logoUrl === "null" )
      return <img className='manin-logo' src={LogoImage} />

    return <img className='manin-logo' src={logoUrl} />
  }

  queryParams(key) {
    let search = this.props.location.search
    let query = new URLSearchParams(search)
    return query.get(key)
  }

  render() {
    if (application.path.includes('/signup') ||
        application.path.includes('/login') ||
        application.path.includes('/app-downloads') ||
        application.path.includes('/marketplace-signup')
       )
      return null
    let navbar = 'new'
    return (
      <div>
        { navbar == 'new' && this.newNavbar()}
        { navbar == 'new' && <div className={classNames("overlay", { active: this.state.showSidebar })}></div>}
        { navbar == 'old' && this.oldNavbar() }
      </div>
    )
  }
  oldNavbar() {
    return (
      <div className="Navbar">
        <nav className="navbar top navbar-expand-sm navbar-light first">
          <div className="container">
            <NavLink className="navbar-brand custom-navbar-brand" to="/home">
              { User.current.logoUrl ? <img src={User.current.logoUrl } /> : <img src={LogoImage} /> }
            </NavLink>
            <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbar-top">
              <span className="navbar-toggler-icon"></span>
            </button>
            <div className="collapse navbar-collapse" id="navbar-top">
              <div className="mr-auto"></div>
              <div className="d-sm-none">
                {this.renderLinks()}
              </div>

              {
                User.current && User.current.regular &&
                  <>
                    <span className="navbar-text">
                      {User.current.picture &&
                        <img src={User.current.picture} className='user-avatar' />
                      }
                      <span className="user-greeting">
                        Good {this.state.dayTime}, {User.current.name}
                      </span>
                    </span>
                    <ul className="navbar-nav">
                      <li className="nav-item">
                        <a className="smart-button primary" href="#" onClick={this.signOut}>Sign Out of Your Account</a>
                      </li>
                    </ul>
                  </>
              }
              {
                User.current && User.current.isGuestExperience &&
                  <>
                    <span className="navbar-text">
                      {User.current.picture &&
                        <img src={User.current.picture} className='user-avatar' />
                      }
                      <span className="user-greeting">
                        {User.current.name}
                      </span>
                    </span>
                    {
                      !App.forceGuestMode &&
                      <ul className="navbar-nav">
                        <li className="nav-item">
                          <a className="smart-button primary" href="#" onClick={this.signOut}>Sign Out of Your Account</a>
                        </li>
                      </ul>
                    }
                  </>
              }
              {
                User.current && User.current.hasGuestPass &&
                <>
                  <ul className="navbar-nav">
                    <li className="nav-item">
                      <Link className="smart-button primary" to="/login">Sign In</Link>
                    </li>
                  </ul>
                </>
              }
            </div>
          </div>
        </nav>

        <nav className="navbar bottom navbar-light navbar-expand-sm d-none d-sm-flex second">
          <div className="container">
            {this.renderLinks()}
          </div>
        </nav>
          </div>
    )
  }
  newNavbar() {
    return (
      <>
        <div className="Navbar">
          <nav id="main-navbar" className="navbar top navbar-light py-2">
            <div className="container-fluid">
              <NavLink className="navbar-brand" to="/home">
                { this.renderLogo() }
              </NavLink>
              <div className="nvb-toggler navbar-text mobile-view" onClick={this.toggleSidebar}>
                  <FontAwesomeIcon icon={faBars} size="lg"/>
                </div>
              <div className="form-inline navbar-link">
                { this.renderNavBarForm() }
                {
                  !application.path.includes('/large-screen') &&
                  <div className="nvb-toggler navbar-text desktop-view" onClick={this.toggleSidebar}>
                    <FontAwesomeIcon icon={faBars} size="lg"/>
                  </div>
                }
              </div>
            </div>
          </nav>
          { this.renderSidebar() }
        </div>
        {
          User.current && User.current.isGuestExperience &&
          <Modal title='Login' opened={this.state.openLoginModal} onClose={this.hideLoginModal}>
            <LoginModal />
          </Modal>
        }
      </>
    )
  }
  renderLinks(parentClass = '') {
    if (application.path.includes('/change-password'))
      return null
    let { menuItems } = this.state
    return (
      <div className={`navbar-nav main-links ${parentClass}`}>
        {menuItems && menuItems.filter(m => m.valid).map(menu =>
          <NavLink
            key={menu.id}
            className={classNames("nav-item nav-link", { unsupported: menu.unsupported })}
            to={'/' + menu.route}
            onClick={this.toggleSidebar}
          >
            {menu.nameText}
          </NavLink>
        )}
        {
          App.user && App.user.regular &&
          <NavLink
            className="nav-item nav-link"
            to="/settings"
            onClick={this.toggleSidebar}
            // isActive={(match, location) => {
            //   if (!match) { return false; }
            //   let path = match.path.substring(1)
            //   return path == '/information' || path.startsWith('/settings')
            // }}
          >
            Settings
          </NavLink>
        }
      </div>
    )
  }
  renderNavBarForm() {
    return (
      <div className="form-inline mr-md-4 m-auto">
        { User.current && User.current.isGuestExperience &&  !application.path.includes('/community-calendar') && !application.path.includes('/large-screen') && this.loginButton() }
        { User.current && User.current.isGuestExperience &&  !application.path.includes('/community-calendar') && !application.path.includes('/large-screen') && this.requestLoginButton()}
        {
          !application.path.includes('/large-screen') &&
          <>
            <SmartButtonLink text="Community Calendar" className="admin-purple inverted primary custom-font-size mb-1 mb-md-0" to="/community-calendar" />
            <SmartButtonLink text="Reservable Locations" className="admin-purple inverted primary custom-font-size mb-1 mb-md-0" to="/places-calendar" />
          </>
        }
      </div>
    )
  }

  loginButton() { 
    let loginEnabled = App.loginOption.includes('user')
    if (loginEnabled)
      return (
        <SmartButtonStub text="Sign In" buttonType="button" className="admin-purple inverted primary custom-font-size mb-1 mb-md-0" click={this.showLoginModal}/>
      )
  }

  requestLoginButton() {
    let loginEnabled = App.loginOption.includes('user')
    let signupEnabled = App.data.features.sign_up_enabled
    if (loginEnabled && signupEnabled)
      return (
        <SmartButtonLink text="Create my Account" className="admin-purple inverted primary custom-font-size mb-1 mb-md-0" to="/signup" />
      )
  }

  renderSidebar() {
    return (
      <nav className={classNames("navbar navbar-light collapse flex-column flex-nowrap align-items-start mw-100 show", {visible: this.state.showSidebar})} id="sidebar">
        <div className="navbar-brand d-flex align-items-center w-100 mr-0 custom-navbar-brand">
          {
            User.current && (User.current.regular || User.current.isGuestExperience) &&
              <div className="user-info d-inline-block">
                <span className="navbar-text sidebar d-flex align-items-center">
                  {User.current.picture &&
                    <img src={User.current.picture} className='user-avatar' />
                  }
                  <span className="user-greeting">
                    {
                      `Good ${this.state.dayTime}, `
                    }
                    {User.current.name}
                  </span>
                </span>
              </div>
          }
          <div className="nvb-toggler d-inline-block ml-auto">
            <div className="navbar-text" onClick={this.toggleSidebar}>
              <FontAwesomeIcon icon={faTimes} size="xl"/>
            </div>
          </div>
        </div>
        { this.renderLinks('flex-nowrap justify-content-start h-100') }
        {
          User.current && User.current.regular &&
          <div className="nav-item my-3 w-100">
            <a className="smart-button primary float-right" href="#" onClick={this.signOut}>Sign Out of Your Account</a>
          </div>
        }
      </nav>
    )
  }

  signOut = () => App.signOut()

  tick = () => {
    let hour = App.now.hour()
    let dayTime =
      hour >= 4 && hour < 12 ? 'Morning' :
      hour >= 12 && hour < 17 ? 'Afternoon' :
      'Evening'
    if (this.state.dayTime != dayTime)
      this.setState({ dayTime })
  }

  toggleSidebar = () => {
    this.setState((state) => ({
      showSidebar: !state.showSidebar,
    }));
  }

  showLoginModal = () => {
    this.setState((state) => ({openLoginModal: true}))
  }
  hideLoginModal = () => {
    console.log(this.state.openLoginModal)
    this.setState((state) => ({openLoginModal: false}))
  }

  async loadMenus() {
    this.setState({ menuItems: await MenuApi.get('load') })
  }
}

export default withRouter(Navbar)
